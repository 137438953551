import React from 'react'
import { Head } from '~/components'
import Login from '~/components/Login'

const LoginPage = () => {
  return (
    <>
      <Head />
      <Login />
    </>
  )
}

export default LoginPage
