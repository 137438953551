import React, { useState } from 'react'
import { Link, navigate } from 'gatsby'
import cn from 'classnames'
import { useCookies } from 'react-cookie'
import { useContext } from '~/hooks'
import useApi from '~/siteApi'

import logoWhite from '~/assets/svg/logo-white.svg'

import * as st from '~/assets/styl/Login.module.styl'
import { Oval } from 'react-loader-spinner'

export default function Login() {
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const username = login
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { setToken, setProfile, setRefreshToken } = useContext()
  const setCookie = useCookies()[1]
  function handleLogin(e) {
    e.preventDefault()
    setIsLoading(true)
    useApi('post', 'auth/login', {
      username,
      password,
    })
      .then(({ token, refresh_token, refresh_token_expiration }) => {
        useApi('get', 'accounts/profile', {
          headers: { Authorization: `Bearer ${token}` },
        }).then(({ result }) => {
          setToken(token)
          setProfile(result)
          setRefreshToken({
            token: refresh_token,
            expiration: refresh_token_expiration,
          })
          setCookie('token', token, {
            path: '/',
            maxAge: Math.floor(refresh_token_expiration - Date.now() / 1000),
          })
          setIsLoading(false)
          navigate('/')
        })
      })
      .catch(({ message }) => {
        // Tratar erro
        setErrorMessage(message)
        setIsLoading(false)
        setError(true)
      })
  }

  return (
    <div className={cn(st.loginContainer, 'page-width')}>
      <Link className={st.closeLogin} to="/">
        <span className={st.close}></span>
      </Link>
      <div className={st.loginWrapper}>
        <img src={logoWhite} alt="" />
        <section className={st.loginModal}>
          <h1>
            Faça seu <b>login</b>
          </h1>
          <form onSubmit={handleLogin} autoComplete="on">
            <label>
              <span className="icon-user"></span>
              <input
                type="email"
                placeholder="Insira seu e-mail"
                onChange={(e) => setLogin(e.target.value)}
                value={login}
                required
              />
            </label>
            <label>
              <span className="icon-password"></span>
              <input
                type="password"
                placeholder="Insira sua senha"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                required
              />
            </label>
            <span className={cn(st.errorMessage, { [st.visible]: error })}>
              {errorMessage}
            </span>
            <Link className={st.forgotMyPassword} to="/esqueci-minha-senha/">
              Esqueci minha senha
            </Link>
            <button type="submit">
              {isLoading ? (
                <Oval
                  height="25"
                  width="25"
                  color="#282b3e"
                  secondaryColor="#CB0103"
                  ariaLabel="loading"
                  wrapperStyle={{ justifyContent: 'center' }}
                />
              ) : (
                'Entrar'
              )}
            </button>
            <Link className={st.register} to="/registro/">
              Criar conta
            </Link>
          </form>
        </section>
      </div>
    </div>
  )
}
